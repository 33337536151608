import { reactive } from 'vue'

const store = reactive({
    usuario: '',
    nombre: '',
    userId: null,
    role: '',
    rowMode: 0,
    timeInterval: 30,
    timeTipo: 0,
    title: 'Home',
    badge: null,
    setTitle(newValue) {
        this.title = newValue
    },
    usuarioSetValue(newValue) {
        this.usuario = newValue
    },
    nombreSetValue(newValue) {
        this.nombre = newValue
    },
    userIdSetValue(newValue) {
        this.userId = newValue
    },
    userSetRole(newValue) {
        this.role = newValue
    },
    userRowModeSetValue(newValue) {
        this.rowMode = newValue
    },
    userTimeIntervalSetValue(newValue) {
        this.timeInterval = newValue
    },
    userTimeTipoSetValue(newValue) {
        this.timeTipo = newValue
    },
    badgeSetValue(newValue) {
        this.badge = newValue
    },
})

export default store