<template>
  <header class="header-component">
    <!-- <dx-toolbar class="header-toolbar"> -->
    <DxToolbar class="header-toolbar">
      <DxItem
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </DxItem>


      <!-- v-if="title" -->

      <DxItem

        location="before"
        css-class="header-title"
      >
      <div class="ms-3 h5">{{ store.title }} </div> 
      <!-- <div>{{ title+'@' }} </div> -->
         <!-- <div class="">{{ dataUser}}</div> -->
      </DxItem>

      <dx-item
        location="after"
        locate-in-menu="auto"
        menu-item-template="menuUserItem"
      >
      <template #default>
          <div>
            <DxButton
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel :user="dataUser" :menu-items="userMenuItems" menu-mode="context" />
          </DxButton>
          </div>
        </template>
      </dx-item>
      
      <template #menuUserItem>
        <user-panel
          :user="dataUser"
          :menu-items="userMenuItems"
          menu-mode="list"
        />
      </template>
    </DxToolbar>
    <!-- </dx-toolbar> -->
  </header>
  <DxPopup
      v-model:visible="popupVisible"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      :show-title="true"
      width="70%"
      :height="700"
      titleTemplate="popupTitle"
      :shading="true"
      shading-color="rgba(0, 0, 0, 0.2)"
    >
      <DxPosition
        at="top"
        my="center"
        collision="fit"
      />
      <!-- <DxToolbarItem
        widget="dxButton"
        toolbar="top"
        locate-in-menu="always"
        :options="marcarLeidoOptions"
      /> -->

        <DxScrollView
          height="100%"
          width="100%"
        >
        <DxDataGrid
          ref="myDataGrid"
          :data-source="dataMessages"
          :column-auto-width="true"
          :show-borders="true"
          :show-row-lines="true"
          :row-alternation-enabled="true"
          :word-wrap-enabled="true"
          :hover-state-enabled="true"
          data-row-template="dataRowTemplate"
          :selected-row-keys="selectedItemKeys"
          @selection-changed="onSelectionChanged"
          @content-ready="getTotalCount"
          height="90%"
        >
          <DxSelection
            mode="multiple"
          />

          <DxColumn data-field="mj_Titulo" caption=""/>
          <DxColumn data-field="mj_Mensaje" caption=""/>
          <DxColumn data-field="us_Nombre" caption=""/>
          <DxColumn data-field="nicedate" caption=""/>

          <template #dataRowTemplate="{ data: rowInfo }">
            <tr role="row">
              <td colspan="4" role="gridcell" class="main-row" >{{ rowInfo.data.mj_Titulo }}</td>
            </tr>
            <tr role="row">
              <td colspan="4" role="gridcell" class="notes-row">{{ rowInfo.data.mj_Mensaje }}</td>
            </tr>
            <tr role="row">
              <td colspan="4" role="gridcell" class="footer-row">{{ rowInfo.data.us_Nombre}} | {{ new Date(rowInfo.data.nicedate).toLocaleDateString('en-GB') }}</td>
            </tr>
            <tr role="row">
              <td colspan="4" role="gridcell" class="footer-row"> </td>
            </tr>
          </template>

        </DxDataGrid>

        </DxScrollView>

      <template #popupTitle>
        <div class="containerToolbar d-flex flex-row justify-content-between">
          <div class="popupTitle">
            Mensajes
          </div>
          <div class="">
            <DxButton
              text="Marcar como leido"
              @click="marcarleido"
              :element-attr="{class: 'btn btn-outline-success btn-sm'}"
              icon="bi bi-bookmark-check"
            >
            </DxButton>
          </div>
        </div>

      </template>
  </DxPopup>
</template>

<script setup>

import DxButton from "devextreme-vue/button"
import DxToolbar, { DxItem } from 'devextreme-vue/toolbar'
import { DxPopup, 
  DxPosition,
  // DxToolbarItem 
} from 'devextreme-vue/popup'
import { DxScrollView } from 'devextreme-vue/scroll-view'
// import DxAccordion from 'devextreme-vue/accordion'
import {
  DxDataGrid,
  DxColumn,
  DxSelection,
  // DxButton,
  // DxToolbar,
  // DxItem,
} from 'devextreme-vue/data-grid'
import CustomStore from 'devextreme/data/custom_store'
import { Auth } from 'aws-amplify'
import router from '../router.js'
import store from '../store/index.js'

// import { useRouter, useRoute } from 'vue-router';
import { ref } from 'vue';

import UserPanel from "./user-panel";

defineProps({
  menuToggleEnabled: Boolean,
  title: String,
  toggleMenuFunc: Function,
  logOutFunc: Function
})

const dataUser = ref(store.nombre);
const baseUrl = 'https://iylotton6j.execute-api.sa-east-1.amazonaws.com/Desarrollo/'
const myDataGrid = ref(null)

const popupVisible = ref(false)
const selectedItemKeys = ref(null)

function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.statusText);
    }
    return response;
}

const dataMessages = new CustomStore({
  key: 'mj_Id',
  load: () => {
    let url = baseUrl + 'mensajes/' + store.userId 
    return fetch(url)
    .then(handleErrors)
    .then(response => response.json())
    .catch(() => { throw 'Problema en la Conexión de Red' + url})
  },
    update: async (key) => {
      var newBody = {
          id: key
          }
      return await fetch(baseUrl + "mensajes", {
          method: 'POST',
          body: JSON.stringify(newBody)
      })
      .then(handleErrors)
      .then( () => {
        myDataGrid.value.instance.refresh()
      })
    },
})


const userMenuItems = [
  {
    text: "Mensajes",
    icon: "bi bi-bell",
    onClick: mensajesView,
    // disabled: true
  },
  {
    text: "Datos del Usuario",
    icon: "user",
    onClick: onProfileClick,
  },
  {
    text: "Salir",
    icon: "bi bi-box-arrow-right",
    onClick: onLogoutClick
}];
  
function onLogoutClick() {
  Auth.signOut()
  router.push('/auth')
  Auth.currentAuthenticatedUser().then(() => {
    console.log('Usuario NO logueado !!!')
  })
}

function onProfileClick() {
  router.push('/profile')
}

function mensajesView() {
  myDataGrid.value.instance.refresh()
  popupVisible.value = true
}

function onSelectionChanged(e) {
  selectedItemKeys.value = e.selectedRowKeys
}

// const marcarLeidoOptions = {
//   text: 'Marcar comoLeido',
//   onClick: () => { marcarleido }
// }

function marcarleido() {
  if (selectedItemKeys.value != null)
  selectedItemKeys.value.forEach(element => {
    dataMessages.update(element)
  });
}

function getTotalCount(e) {
  let cant = e.component.totalCount()
  store.badgeSetValue(cant == 0 ? null : cant)
}


</script>

<style scoped lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 1;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}

.popupTitle {
  color: rgb(243,112,32);
  font-size: 1.5em;
}

.main-row {
  font-size: 1.2em;
  font-weight: bold;
}

.notes-row {
  color:rgb(130, 133, 133);
}

.footer-row {
  font-size: 0.8em;
  color:rgb(130, 133, 133);
}
</style>
